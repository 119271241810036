<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalStatisticsPlayer" :title="player.nick" @shown="init">
      <div class="form-group">
        <label for="goals">{{ $tc('goal', 2) | toCapitalize }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('go')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.goals"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('go')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group">
        <label for="goals">{{ $tc('assistance', 2) | toCapitalize }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('as')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.assistances"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('as')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group">
        <label for="goals">{{ $tc('yellowCard', 2) | toCapitalize }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('ye')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.yellows"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('ye')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group">
        <label for="goals">{{ $tc('redCard', 2) | toCapitalize }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('re')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.reds"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('re')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group">
        <label for="goals">{{ $t('bestPlayer') }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('be')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.bests"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('be')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="form-group mt-5" v-if="player.position === 'GK'">
        <h5>{{ $tc('goalkeeper', 1) | toCapitalize }}</h5>
        <hr>
        <label for="played_games">{{ $t('playedGames') }}</label>
        <select name="played_games" id="played_games" class="form-control" v-model="statistics.played_games">
          <option value="0">{{ $t('none') }}</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
      <div class="form-group" v-if="player.played_games !== 0 && player.position === 'GK'">
        <label for="goals">{{ $tc('goalAgainst', 2) | toCapitalize }}</label>
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="info" @click="add('ga')"><i class="fa fa-plus px-2"></i></b-button>
          </b-input-group-prepend>
          <b-form-input type="number" min="0" class="text-center font-weight-bold" readonly v-model="statistics.goals_against"></b-form-input>
          <b-input-group-append>
            <b-button variant="danger" @click="sub('ga')"><i class="fa fa-minus px-2"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalStatisticsPlayer')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    player: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      statistics: {}
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    init () {
      this.statistics = this.player
    },
    add (type) {
      if (type === 'go') {
        this.statistics.goals += 1
      }
      if (type === 'as') {
        this.statistics.assistances += 1
      }
      if (type === 'ye') {
        this.statistics.yellows += 1
      }
      if (type === 're') {
        this.statistics.reds += 1
      }
      if (type === 'be') {
        this.statistics.bests += 1
      }
      if (type === 'ga') {
        this.statistics.goals_against += 1
      }
    },
    sub (type) {
      if (type === 'go') {
        if (this.statistics.goals > 0) {
          this.statistics.goals -= 1
        }
      }
      if (type === 'as') {
        if (this.statistics.assistances > 0) {
          this.statistics.assistances -= 1
        }
      }
      if (type === 'ye') {
        if (this.statistics.yellows > 0) {
          this.statistics.yellows -= 1
        }
      }
      if (type === 're') {
        if (this.statistics.reds > 0) {
          this.statistics.reds -= 1
        }
      }
      if (type === 'be') {
        if (this.statistics.bests > 0) {
          this.statistics.bests -= 1
        }
      }
      if (type === 'ga') {
        if (this.statistics.goals_against > 0) {
          this.statistics.goals_against -= 1
        }
      }
    },
    save () {
      this.$emit('updateStatistics', this.statistics)
      this.$root.$emit('bv::hide::modal', 'modalStatisticsPlayer')
    }
  }
}
</script>
